.iconStyle {
  font-size: 16px !important;
}
.acceptBtn {
  color: #009966 !important;
  background-color: white !important;
}
.rejectBtn {
  color: #bf2600 !important;
  background-color: white !important;
}
.acceptWrapper {
  border: 1px solid #009966;
  border-radius: 4px;
}
.rejectWrapper {
  border: 1px solid #bf2600;
  border-radius: 4px;
}
.checkIcon {
  fill: #009966 !important;
  width: 16px !important;
  height: 16px !important;
}
.crossIcon {
  fill: #bf2600 !important;
  width: 16px !important;
  height: 16px !important;
}
