.cardWrapper {
  display: flex;
  padding: 24px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background: #eeecf9;
  margin-bottom: 16px;
}

.bold {
  font-weight: 700;
}

.selectionTextStyle {
  color: #272522;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.linkStyle {
  color: #493ab1;
  font-weight: 500;
  cursor: pointer;
}

.textWrapper {
  display: flex;
  gap: 6px;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

@media screen and (max-width: 780px) {
  .cardWrapper {
    display: flex;
    padding: 8px 16px;
    align-items: start;
    border-radius: 6px;
    background: #eeecf9;
    margin-bottom: 0px;
    flex-direction: column;
  }

  .textWrapper {
    margin-bottom: 10px;
  }

  .selectionTextStyle {
    font-size: 14px;
  }
}
