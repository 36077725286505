.btnLabelStyle {
  gap: 6px !important;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.btnStyle {
  height: 36px !important;
}

@media (max-width: 768px) {
  .btnLabelStyle {
    padding: 4px 16px;
  }

  .btnWrapperFull {
    width: 100%;
  }
}
